import React from "react";
import { Link } from "react-router-dom";
import { Grid, Box, Typography, Container } from "@mui/material";

import JobDetailCard from "../cards/job_detail.cards.components";
import {
  JobDetailInternational,
  JobDetailNational,
} from "../cards/job_detail.data.components";

import "./listing.styles.scss";

const ForSkilled = ({ setOpenJobApplicationForm }) => {
  const [active, setActive] = React.useState("National");

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        // border:"1px solid yellow",
        backgroundColor:"#f2f1fd"
      }}
    >
        <Grid item container className="info_skilled"
          sx={{
            padding:{sm:"30px 0px"},
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center"
          }}
        >
          <Grid 
            className="just_info" 
            sx={{ 
                marginBottom: "2rem" ,
                display:"flex",
                flexDirection:"column",
              }}
          >
            <Box className="type">
              <Typography
                variant="body1"
                component="p"
                sx={{ 
                  fontSize: { xs: "14px", sm: "24px",md:"32px" }, 
                  // border:"1px solid black",
                  textAlign: "center",
                  textTransform:"uppercase",
                  fontFamily:"Roboto-Serif",
                  color:"#f15b24",
                  fontWeight:'600'
                  // float: "right" 
                }}
              >
                Unskilled
              </Typography>
            </Box>
            <Box
              className="geography"
              sx={{
                gap: 2,
              }}
            >
              <Typography
                variant="body1"
                component="p"
                sx={{ 
                  fontSize: { xs: "12px", sm: "16px",md:"20px" }, 
                  cursor: "pointer",
                  color: "#000",
                  fontFamily:"Roboto-Serif"
                }}
                className={active === "National" ? "active__text" : ""}
                onClick={() => setActive("National")}
              >
                National
              </Typography>
              <Typography
                variant="body1"
                component="p"
                sx={{ 
                  fontSize: { xs: "12px", sm: "16px",md:"20px"}, 
                  cursor: "pointer",
                  color: "#000",
                  fontFamily:"Roboto-Serif"
                }}
                className={active === "International" ? "active__text" : ""}
                onClick={() => setActive("International")}
              >
                International
              </Typography>
            </Box>

            
          </Grid>
          <Grid
            container
            spacing={1}
            display="flex"
            alignItems="center"
            justifyContent="space-evenly"
            sx={{
              width: { xs: "100%", sm: "100%", lg: "80%" },
              padding: { xs: "10px", sm: "20px", lg: "0px" },
              // backgroundColor: "blue",
            }}
          >
            {active === "International"
              ? JobDetailInternational.filter((jobContent, idx) => idx < 4).map(
                  (jobContent) => (
                    <JobDetailCard
                      key={jobContent.id}
                      jobContent={jobContent}
                      setOpenJobApplicationForm={setOpenJobApplicationForm}
                    />
                  )
                )
              : JobDetailNational.filter((jobContent, idx) => idx < 4).map(
                  (jobContent) => (
                    <JobDetailCard
                      key={jobContent.id}
                      jobContent={jobContent}
                      setOpenJobApplicationForm={setOpenJobApplicationForm}
                    />
                  )
                )}
          </Grid>
          <Box className="s_all">
              <Typography
                sx={{ 
                  // float: "right", 
                  fontSize: { xs: "12px", sm: "16px" } ,
                  color:"#000",
                  // border:"1px solid red"
                }}
                component={Link}
                to="/unskilled"
                replace={true}
              >
                See All
              </Typography>
          </Box>
        </Grid>
    </Container>
  );
};
export default ForSkilled;
