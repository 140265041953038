// import img1 from "../../images/img1.jpg";
// import img2 from "../../images/img2.jpg";
// import img3 from "../../images/img3.jpg";
// import img4 from "../../images/img4.jpg";
// import img5 from "../../images/img5.jpg";
// import img6 from "../../images/img6.jpg";
export const JobDetailInternational = [
  {
    id: 1,
    company: "Alkaline Pvt Ltd",
    title: "Security",
    demand: 230,
    age: "Not Above 35",
    country: "Malaysia",
    salary: 2700,
    accommodation: "Yes",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/01/22",
  },
  {
    id: 2,
    company: "Visco Pvt Ltd",
    title: "Helper",
    demand: 20,
    age: "Not Above 35",
    country: "Qatar",
    salary: 1200,
    accommodation: "Yes",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/01/22",
  },
  {
    id: 3,
    company: "Gulf Suppliers",
    title: "Packing",
    demand: 40,
    age: "Not Above 35",
    country: "Dubai",
    salary: 1700,
    accommodation: "Yes",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/01/22",
  },
  {
    id: 4,
    company: "Where it ends, it begins.",
    title: "Live Guard",
    demand: 80,
    age: "Not Above 35",
    country: "Malaysia",
    salary: 1800,
    accommodation: "Yes",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/01/22",
  },
  {
    id: 5,
    company: "ABC Company",
    title: "Sales Representative",
    demand: 50,
    age: "Not Above 35",
    country: "United States",
    salary: 3000,
    accommodation: "No",
    fooding: "No",
    ticket: "No",
    deadline: "2023/02/15",
  },
  {
    id: 6,
    company: "XYZ Corporation",
    title: "Software Engineer",
    demand: 10,
    age: "Not Above 35",
    country: "Canada",
    salary: 5000,
    accommodation: "Yes",
    fooding: "No",
    ticket: "No",
    deadline: "2023/03/10",
  },
  {
    id: 7,
    company: "Tech Solutions Ltd",
    title: "Network Administrator",
    demand: 15,
    age: "Not Above 35",
    country: "United Kingdom",
    salary: 4000,
    accommodation: "No",
    fooding: "No",
    ticket: "No",
    deadline: "2023/02/28",
  },
  {
    id: 8,
    company: "Global Logistics",
    title: "Warehouse Manager",
    demand: 25,
    age: "Not Above 35",
    country: "Germany",
    salary: 3500,
    accommodation: "Yes",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/02/10",
  },
  {
    id: 9,
    company: "Digital Marketing Agency",
    title: "Social Media Specialist",
    demand: 5,
    age: "Not Above 35",
    country: "Australia",
    salary: 2500,
    accommodation: "No",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/03/15",
  },
  {
    id: 10,
    company: "Healthcare Solutions",
    title: "Medical Transcriptionist",
    demand: 8,
    age: "Not Above 35",
    country: "India",
    salary: 1800,
    accommodation: "No",
    fooding: "No",
    ticket: "No",
    deadline: "2023/02/28",
  },
  {
    id: 11,
    company: "Creative Design Studio",
    title: "Graphic Designer",
    demand: 12,
    age: "Not Above 35",
    country: "Spain",
    salary: 2800,
    accommodation: "Yes",
    fooding: "No",
    ticket: "No",
    deadline: "2023/03/10",
  },
  {
    id: 12,
    company: "E-commerce Solutions",
    title: "Product Manager",
    demand: 7,
    age: "Not Above 35",
    country: "France",
    salary: 4000,
    accommodation: "Yes",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/02/22",
  },
  {
    id: 13,
    company: "Construction Co.",
    title: "Civil Engineer",
    demand: 10,
    age: "Not Above 35",
    country: "United Arab Emirates",
    salary: 5000,
    accommodation: "Yes",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/03/05",
  },
  {
    id: 14,
    company: "Hospitality Group",
    title: "Hotel Manager",
    demand: 5,
    age: "Not Above 35",
    country: "Singapore",
    salary: 3500,
    accommodation: "Yes",
    fooding: "Yes",
    ticket: "No",
    deadline: "2023/02/18",
  },
  {
    id: 15,
    company: "Financial Services Ltd",
    title: "Accountant",
    demand: 15,
    age: "Not Above 35",
    country: "Netherlands",
    salary: 3800,
    accommodation: "No",
    fooding: "No",
    ticket: "No",
    deadline: "2023/03/15",
  },
  {
    id: 16,
    company: "Fashion Retailers",
    title: "Store Manager",
    demand: 8,
    age: "Not Above 35",
    country: "Italy",
    salary: 3200,
    accommodation: "Yes",
    fooding: "No",
    ticket: "No",
    deadline: "2023/02/25",
  },
  {
    id: 17,
    company: "Media Productions",
    title: "Video Editor",
    demand: 10,
    age: "Not Above 35",
    country: "Japan",
    salary: 4500,
    accommodation: "No",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/03/10",
  },
  {
    id: 18,
    company: "Automotive Manufacturer",
    title: "Mechanical Engineer",
    demand: 7,
    age: "Not Above 35",
    country: "South Korea",
    salary: 4200,
    accommodation: "Yes",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/02/28",
  },
  {
    id: 19,
    company: "Education Institute",
    title: "Teacher",
    demand: 20,
    age: "Not Above 35",
    country: "Canada",
    salary: 2500,
    accommodation: "No",
    fooding: "No",
    ticket: "No",
    deadline: "2023/03/15",
  },
  {
    id: 20,
    company: "Technology Solutions",
    title: "IT Support Specialist",
    demand: 12,
    age: "Not Above 35",
    country: "United States",
    salary: 3500,
    accommodation: "No",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/02/22",
  },
  {
    id: 21,
    company: "Technology Solutions",
    title: "IT Support Specialist",
    demand: 12,
    age: "Not Above 35",
    country: "United States",
    salary: 3500,
    accommodation: "No",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/02/22",
  },
];
export const JobDetailNational = [
  {
    id: 1,
    company: "Alkaline Pvt ltd",
    title: "Security",
    demand: 230,
    age: "Not Above 35",
    country:"Nepal",
    salary: 2700,
    accommodation: "Yes",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/01/22",
    // profileUrl: img1,
  },
  {
    id: 2,
    company: "Visco Pvt ltd",
    title: "Helper",
    demand: 20,
    age: "Not Above 35",
    country:"Nepal",
    salary: 1200,
    accommodation: "Yes",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/01/22",
    // profileUrl: img2,
  },
  {
    id: 3,
    company: "Gulf suppliers  ",
    title: "Packing",
    demand: 40,
    age: "Not Above 35",
    country:"Nepal",
    salary: 1700,
    accommodation: "Yes",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/01/22",
    // profileUrl: img3,
  },
  {
    id: 4,
    company: "Where it ends , it begins.",
    title: "Live Guard",
    demand: 80,
    age: "Not Above 35",
    country:"Nepal",
    salary: 1800,
    accommodation: "Yes",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/01/22",
    // profileUrl: img4,
  },
  {
    id: 5,
    company: "Tech Solutions Inc.",
    title: "Software Developer",
    demand: 10,
    age: "Not Above 35",
    country:"Nepal",
    salary: 3500,
    accommodation: "Yes",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/02/15",
    // profileUrl: img5,
  },
  {
    id: 6,
    company: "Global Logistics Ltd.",
    title: "Warehouse Manager",
    demand: 5,
    age: "Not Above 40",
    country:"Nepal",
    salary: 2500,
    accommodation: "Yes",
    fooding: "Yes",
    ticket: "No",
    deadline: "2023/01/22",
    // profileUrl: img6,
  },
  {
    id: 7,
    company: "Green Energy Solutions",
    title: "Solar Panel Technician",
    demand: 15,
    age: "Not Above 40",
    country:"Nepal",
    salary: 2000,
    accommodation: "No",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/02/28",
    // profileUrl: img7,
  },
  {
    id: 8,
    company: "Healthcare Services Inc.",
    title: "Nurse",
    demand: 30,
    age: "Not Above 40",
    country:"Nepal",
    salary: 1800,
    accommodation: "Yes",
    fooding: "Yes",
    ticket: "No",
    deadline: "2023/02/10",
    // profileUrl: img8,
  },
  {
    id: 9,
    company: "Creative Advertising Agency",
    title: "Graphic Designer",
    demand: 8,
    age: "Not Above 35",
    country:"Nepal",
    salary: 2800,
    accommodation: "No",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/03/15",
    // profileUrl: img9,
  },
  {
    id: 10,
    company: "Hospitality Group",
    title: "Front Desk Receptionist",
    demand: 5,
    age: "Not Above 30",
    country:"Nepal",
    salary: 1500,
    accommodation: "Yes",
    fooding: "No",
    ticket: "No",
    deadline: "2023/02/28",
    // profileUrl: img10,
  },
  {
    id: 11,
    company: "Tech Innovators Ltd.",
    title: "IT Support Specialist",
    demand: 12,
    age: "Not Above 35",
    country:"Nepal",
    salary: 3200,
    accommodation: "Yes",
    fooding: "No",
    ticket: "Yes",
    deadline: "2023/02/20",
    // profileUrl: img11,
  },
  {
    id: 12,
    company: "E-commerce Solutions Inc.",
    title: "Digital Marketing Manager",
    demand: 3,
    age: "Not Above 40",
    country:"Nepal",
    salary: 4000,
    accommodation: "Yes",
    fooding: "Yes",
    ticket: "Yes",
    deadline: "2023/03/10",
    // profileUrl: img12,
  },
  {
    id: 13,
    company: "Financial Services Group",
    title: "Accountant",
    demand: 7,
    age: "Not Above 35",
    country:"Nepal",
    salary: 2500,
    accommodation: "No",
    fooding: "No",
    ticket: "No",
    deadline: "2023/02/15",
    // profileUrl: img13,
  },
  {
    id: 14,
    company: "Tourism and Travel Agency",
    title: "Tour Guide",
    demand: 10,
    age: "Not Above 40",
    country:"Nepal",
    salary: 1600,
    accommodation: "Yes",
    fooding: "No",
    ticket: "No",
    deadline: "2023/02/28",
    // profileUrl: img14,
  },
  {
    id: 15,
    company: "Education Institute",
    title: "Teacher",
    demand: 25,
    age: "Not Above 40",
    country:"Nepal",
    salary: 2200,
    accommodation: "No",
    fooding: "Yes",
    ticket: "No",
    deadline: "2023/03/15",
    // profileUrl: img15,
  },
  {
    id: 16,
    company: "Construction Company",
    title: "Civil Engineer",
    demand: 8,
    age: "Not Above 35",
    country:"Nepal",
    salary: 3000,
    accommodation: "Yes",
    fooding: "Yes",
    ticket: "Yes",
    deadline: "2023/03/10",
    // profileUrl: img16,
  },
  {
    id: 17,
    company: "Media Productions",
    title: "Video Editor",
    demand: 6,
    age: "Not Above 35",
    country:"Nepal",
    salary: 2000,
    accommodation: "No",
    fooding: "No",
    ticket: "No",
    deadline: "2023/02/20",
    // profileUrl: img17,
  },
  {
    id: 18,
    company: "Healthcare Services Inc.",
    title: "Pharmacist",
    demand: 10,
    age: "Not Above 40",
    country:"Nepal",
    salary: 2400,
    accommodation: "Yes",
    fooding: "Yes",
    ticket: "Yes",
    deadline: "2023/02/15",
    // profileUrl: img18,
  },
  {
    id: 19,
    company: "Construction Company",
    title: "Civil Engineer",
    demand: 8,
    age: "Not Above 35",
    country:"Nepal",
    salary: 3000,
    accommodation: "Yes",
    fooding: "Yes",
    ticket: "Yes",
    deadline: "2023/03/10",
    // profileUrl: img16,
  },
  {
    id: 20,
    company: "Media Productions",
    title: "Video Editor",
    demand: 6,
    age: "Not Above 35",
    country:"Nepal",
    salary: 2000,
    accommodation: "No",
    fooding: "No",
    ticket: "No",
    deadline: "2023/02/20",
    // profileUrl: img17,
  },
  {
    id: 21,
    company: "Healthcare Services Inc.",
    title: "Pharmacist",
    demand: 10,
    age: "Not Above 40",
    country:"Nepal",
    salary: 2400,
    accommodation: "Yes",
    fooding: "Yes",
    ticket: "Yes",
    deadline: "2023/02/15",
    // profileUrl: img18,
  },
];
