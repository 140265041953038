import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Drawer,
  Dialog,
  DialogContent,
  Slide,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import "./navigation_bar.styles.scss";

import LoginPopupForm from "../../components/form/popup_login_form";
import WorkerSignupForm from "../../components/form/popup_worker_signup_form";
import EmployerSignupForm from "../../components/form/popup_employer_signup_form";
import {
  openLoginDialog,
  closeLoginDialog,
  closeSignupDialog,
  selectedFormWorker,
  selectedFormEmployer,
} from "../../redux/dialogSlice";
import NavDrawer from "./nav_drawer";
import NavItemsAndLogo from "./nav_items_and_logo";
// import { logout } from "../../redux/authSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 140;

// DEFAULT NAVIGATION ITEMS
const guestNavItems = [
  { label: "Home", url: "/" },
  { label: "About Us", url: "/about" },
  { label: "Contact", url: "/contact" },
];

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "green",
  "&.MuiToolbar-root": {
    backgroundColor: "transparent",
  },
});

const GuestNavBar = () => {
  const [aBar, setAppBar] = useState(false);

  const [mobileOpen, setMobileOpen] = useState(false);

  const dispatch = useDispatch();

  const loginDialog = useSelector((state) => state.dialog.loginDialog);
  const signupDialog = useSelector((state) => state.dialog.signupDialog);
  const selectedForm = useSelector((state) => state.dialog.selectedForm);

  const navBarItemsRightMobile = (
    <>
      <Box
        sx={{
          borderRadius: "5px",
          padding: "2px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton>
          <PersonAddIcon sx={{ color: "#fff", fontSize: "14px" }} />
        </IconButton>
        <Typography
          sx={{ 
            fontSize: "14px", 
            cursor: "pointer",
            fontFamily:"Roboto-Serif"
          }}
          onClick={() => {
            // setSelectedForm("worker");
            // setOpenLoginForm(true);
            dispatch(selectedFormWorker());
            dispatch(openLoginDialog());
          }}
        >
          Worker
        </Typography>
      </Box>
      <Box
        sx={{
          borderRadius: "5px",
          padding: "2px",
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <IconButton>
          <PersonAddIcon sx={{ color: "#fff", fontSize: "14px" }} />
        </IconButton>
        <Typography
          sx={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => {
            // setSelectedForm("employer");
            // setOpenLoginForm(true);
            dispatch(selectedFormEmployer());
            dispatch(openLoginDialog());
          }}
        >
          Employer
        </Typography>
      </Box>
    </>
  );
  const navBarItemsRightDesktop = (
    <>
      <Box
        sx={{
          padding: "2px 8px 2px 1px",
          display: "flex",
          alignItems: "center",
          // backgroundColor: "yellow",
          position: "relative",
        }}
        className="divider_w_e"
      >
        <IconButton>
          <PersonAddIcon sx={{ color: "#fff", fontSize: "14px" }} />
        </IconButton>
        <Typography
          sx={{ fontSize:  "14px", cursor: "pointer" }}
          onClick={() => {
            // setSelectedForm("worker");
            dispatch(selectedFormWorker());
            // setOpenLoginForm(true);
            dispatch(openLoginDialog());
          }}
        >
          Worker
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "2px 8px 2px 1px",
          display: "flex",
          alignItems: "center",
          // backgroundColor: "yellow"
        }}
      >
        <IconButton>
          <PersonAddIcon sx={{ color: "#fff", fontSize: "14px" }} />
        </IconButton>
        <Typography
          sx={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => {
            // setSelectedForm("employer");
            // setOpenLoginForm(true);
            dispatch(selectedFormEmployer());
            dispatch(openLoginDialog());
          }}
        >
          Employer
        </Typography>
      </Box>
    </>
  );

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const closeFormDialog = () => {
    dispatch(closeSignupDialog());
    dispatch(closeLoginDialog());
  };

  const changingOnScroll = () => {
    if (window.scrollY >= 1) {
      setAppBar(true);
    } else {
      setAppBar(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener("scroll", changingOnScroll);
    return () => {
      window.removeEventListener("scroll", changingOnScroll);
    };
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        sx={{
          backgroundColor: `${aBar ? "#1A164B" : "#272361"} !important`,

          boxShadow: `${!aBar ? "none" : ""}`,
          transitionProperty: "all",
          transitionDuration: "0.6s",
          transitionDelay: "0.1s",
          transitionTimingFunction: "ease-in-out",
        }}
      >
        <StyledToolbar>
          {/* logo section */}
          <NavItemsAndLogo navItems={guestNavItems} />
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
            }}
          >
            {/* Nav Items Right Desktop */}
            {navBarItemsRightDesktop}
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "none" },
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </StyledToolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              overflow: "hidden",
              backgroundColor: "#1A164B",
            },
          }}
        >
          <NavDrawer
            handleDrawerToggle={handleDrawerToggle}
            navBarItemsRightMobile={navBarItemsRightMobile}
            navItems={guestNavItems}
          />
        </Drawer>
      </Box>
      {/* LOGIN FORM DIALOG */}
      <Dialog
        open={loginDialog}
        onClose={closeFormDialog}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        // fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "10px",
            backgroundColor: "#272361",
            width: "35rem",
            height: "35rem",
          },
        }}
      >
        <DialogActions sx={{ padding: "0" }}>
          <IconButton onClick={closeFormDialog}>
            <CloseIcon fontSize="large" sx={{ fill: "#D9D9D9" }} />
          </IconButton>
        </DialogActions>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LoginPopupForm />
        </DialogContent>
      </Dialog>
      {/* SIGNUP FORM DIALOG */}
      <Dialog
        open={signupDialog}
        onClose={closeFormDialog}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        // fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "10px",
            backgroundColor: "#272361",
            width: "35rem",
            height: "35rem",
          },
        }}
      >
        <DialogActions sx={{ padding: "0" }}>
          <IconButton onClick={closeFormDialog}>
            <CloseIcon fontSize="large" sx={{ fill: "#D9D9D9" }} />
          </IconButton>
        </DialogActions>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          {selectedForm === "worker" ? (
            <WorkerSignupForm />
          ) : (
            <EmployerSignupForm />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default GuestNavBar;
