import React from "react";

//IMPORT MATERIAL UI
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CompanyProfileImage from '../../assets/images/company_profile.png'
//IMPORT COMPONENTS
// import img1 from '../../images/img1.jpg';
// import Sliding from "./sliding.components";

import "./imageSlider.styles.scss";
const ImageSlider = () => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        backgroundColor:"#eceaff",
        width:"100%"
        // marginTop: { xs: "50px", sm: "68px", md: "100px" },
      }}
    >
      <Grid
        container
        sx={{
          marginTop: { xs: "50px", sm: "68px", md: "100px" },
          height: { xs: "260px", sm: "400px", md: "450px", lg: "700px" },
          backgroundImage:`url(${CompanyProfileImage})`,
          backgroundRepeat:"no-repeat",
          backgroundPosition:"center",
          backgroundSize:"contain",
          // border:"1px solid red"
        }}
        className="img-container"
      >
        <Box 
        className="image-slider"
        sx={{
          // border:"2px solid yellow",
          backgroundColor:"rgba(0,0,0,0.5)",
          width:"100%"
          
        }} >
          {/* <Sliding /> */}
          <Typography
            variant="h4"
            textAlign="center"
            sx={{
              color:"#ffffff",
              fontSize:{xs:"18px",sm:"26px",md:"34px",lg:"40px"},
              textShadow:"0px 1px 0px  #000",
              textTransform:"uppercase",
              fontWeight:"600",
              fontFamily:"Roboto-Serif"
            }}
          >
            Api  manpower solutions
          </Typography>
          <Typography
            variant="h4"
            textAlign="center"
            sx={{
              color:"#ffffff",
              fontSize:{xs:"14px",sm:"20px",md:"28px",lg:"30px"},
              textShadow:"0px 1px 0px  #000",
              textTransform:"capitalize",
              fontWeight:"400",
              fontFamily:"Roboto-Serif"
            }}
          >
            private limited
          </Typography>
        </Box>
        <Box
          className="search-bar"
          sx={{
            height: { xs: "24px", sm: "50px" },
            backgroundColor: "#ffffff",
            padding: { xs: "2px 0px 2px 6px", sm: "4px 10px" },
            border:"1px solid blue",
            width:{xs:"90%",sm:"70%"}
          }}
        >
          <input
            type="text"
            placeholder="Search by Job Title,Skill and Company"
            className="search-input"
          />
          <IconButton>
            <SearchIcon
              sx={{
                backgroundColor: "#d9d9d9",
                color: "#000000",
                fontSize: { xs: "20px", sm: "36px" },
                padding: "4px",
                borderRadius: "50%",
              }}
            />
          </IconButton>
        </Box>
      </Grid>
    </Container>
  );
};
export default ImageSlider;
