import React from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
  Slide,
  IconButton,
  Container,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import ForUnskilled from "./unskilled.components";
import ForSkilled from "./skilled.components";
import JobApplicationForm from "../form/popup_job_application_form";

import "./listing.styles.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Listing = () => {
  const [openJobApplicationForm, setOpenJobApplicationForm] =
    React.useState(false);

  return (
    <Container
    maxWidth={false}
    disableGutters
      sx={{
        width:"100%",
        // backgroundColor:"red"
        // border:"1px solid green"
      }}
    >
      <Grid container className="listing"
        sx={{
          // border:"1px solid yellow"
        }}
      >
        <ForUnskilled setOpenJobApplicationForm={setOpenJobApplicationForm} />
        <ForSkilled setOpenJobApplicationForm={setOpenJobApplicationForm} />
      </Grid>
      {/* create job application dialog */}
      <Dialog
        open={openJobApplicationForm}
        onClose={() => setOpenJobApplicationForm(false)}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "10px",
            backgroundColor: "#272361",
          },
        }}
      >
        <DialogActions sx={{ padding: "0" }}>
          <IconButton onClick={() => setOpenJobApplicationForm(false)}>
            <CloseIcon fontSize="large" sx={{ fill: "#D9D9D9" }} />
          </IconButton>
        </DialogActions>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "0 1rem 1rem 1rem",
          }}
        >
          <JobApplicationForm />
        </DialogContent>
      </Dialog>
    </Container>
  );
};
export default Listing;
