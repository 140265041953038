import React from "react";
import { Typography, Box, Stack } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import theme from "../../theme.jsx";
import { Link } from "react-router-dom";
const Footer = () => {
  const date = new Date();
  // console.log("Date:",date.getFullYear());
  const year = date.getFullYear();
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        padding: "80px 20px 20px 20px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="1rem"
      >
        <Stack direction="row" spacing={1.5} justifyContent="center">
          <LinkedInIcon />
          <FacebookIcon />
          <YouTubeIcon />
          <TwitterIcon />
        </Stack>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{ color: "#ffffff", textAlign: "center" }}
          >
            Api Manpower Solution Pvt. Ltd
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "#ffffff)", textAlign: "center" }}
          >
            Bhanimandal-1, Lalitpur, Nepal
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={{ xs: 0, sm: 1 }}
          >
            <MailIcon />
            <Stack direction={{ xs: "column", sm: "row" }}>
              <Typography sx={{ textAlign: "center",color: "#ffffff)" }}>
                info@apimanpower.com
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="center"
          >
            <CallIcon />
            <Typography
              variant="body2"
              sx={{ textAlign: "center", color: "#ffffff" }}
            >
              +977-01-5454927 | 01-5454928
            </Typography>
          </Stack>
          <Typography variant="body1" 
            sx={{ 
              textAlign: "center" ,
              fontSize:{xs:"14px"},
              color: "rgb(200,200,200)"
            }}>
            Copyright @{year} | Api Manpower Solution Pvt. Ltd.
          </Typography>
        </Box>
        {/* Last Row */}
        <Box
          display="flex"
          // gap={{ xs: 3, sm: 4, md: "6" }}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          {/* <Typography>Cookie Policy </Typography>
          <Box
            sx={{
              height: "0.8rem",
              borderLeft: "2px solid white",
              display: { xs: "none", sm: "block" },
            }}
          /> */}
          {/* <Typography>Privacy Policy</Typography>
          <Box
            sx={{
              height: "0.8rem",
              borderLeft: "2px solid white",
              display: { xs: "none", sm: "block" },
            }}
          />
          <Typography>Terms Of Use</Typography> */}
          {/* <Box
            sx={{
              height: "0.8rem",
              borderLeft: "2px solid white",
              display: { xs: "none", sm: "block" },
            }}
          /> */}
          <Link to="https://blackmoon.com.np/" target="_blank">
            <Typography
              sx={{
                fontSize:"12px"
              }}
            >Developed By Black Moon</Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
export default Footer;
