import React from "react";
import { useSelector, useDispatch } from "react-redux";

// MUI IMPORTS
import {
  Box,
  Typography,
  Button,
  CardActions,
  Card,
  CardContent,
} from "@mui/material";

import "./cards.styles.scss";
import { openLoginDialog, selectedFormWorker } from "../../redux/dialogSlice";
const JobDetailCard = ({ jobContent, setOpenJobApplicationForm }) => {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.auth.role);
  function openJobApplicationDialog() {
    if (role === "worker") {
      setOpenJobApplicationForm(true);
    } else {
      dispatch(selectedFormWorker());
      dispatch(openLoginDialog());
    }
  }
  return (
    <Card
      sx={{
        width: { xs: "100%", sm: 220, md: 220, lg: 280 },
        minHeight: { xs: 120, md: 160 },
        // border: "1px solid black",
        borderRadius: { xs: "20px 0px 20px 0px" },
        backgroundColor: "#c0bbe5",
        margin: { xs: "10px", sm: "10px", md: "10px 4px", lg: "10px 0px" },
        boxShadow: "0px 2px 2px rgba(0,0,0,0.3)",

        // backgroundImage:`url(${jobContent.profileUrl})`,
        backgroundSize: "100% 100%",
      }}
    >
      <CardContent
        sx={{
          backgroundColor: "transparent",
          padding: { xs: "8px", sm: "10px", md: "16px" },
          "&:last-child": {
            paddingBottom: { xs: "8px", sm: "10px", md: "16px" },
          },
        }}
      >
        <Typography
          gutterBottom
          variant="body1"
          component="p"
          sx={{
            fontSize: { xs: "16px", sm: "18px", md: "20px" },
            fontWeight: "600",
            margin: "0px",
            fontFamily:"Roboto-Serif",
            textAlign:"center"
          }}
        >
          {jobContent.company}
        </Typography>
        <Typography
          gutterBottom
          variant="body1"
          component="p"
          sx={{
            fontSize: { xs: "12px", sm: "14px", md: "18px" },
            fontWeight: "500",
            margin: "0px",
            fontFamily:"Roboto-Serif",
            textAlign:"center"
          }}
        >
          ({jobContent.title})
        </Typography>
        <table>
          <tbody>
            <tr
            >
              <td
              >
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"}}}
                >
                  Demand
                </Typography>
              </td>
              <td>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"} }}
                >
                  :{jobContent.demand}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"} }}
                >
                  Age
                </Typography>
              </td>
              <td>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"} }}
                >
                  :{jobContent.age}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"} }}
                >
                  Country
                </Typography>
              </td>
              <td>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"} }}
                >
                  :{jobContent.country}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"} }}
                >
                  Salary
                </Typography>
              </td>
              <td>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"} }}
                >
                  :{jobContent.salary}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"} }}
                >
                  Accommodation
                </Typography>
              </td>
              <td>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"} }}
                >
                  :{jobContent.accommodation}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"} }}
                >
                  Fooding
                </Typography>
              </td>
              <td>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"} }}
                >
                  :{jobContent.fooding}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"} }}
                >
                  Ticket
                </Typography>
              </td>
              <td>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"} }}
                >
                  :{jobContent.ticket}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"} }}
                >
                  Deadline
                </Typography>
              </td>
              <td>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontFamily:"Roboto-Serif",fontSize: { xs: "14px"} }}
                >
                  :{jobContent.deadline}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </CardContent>
      <CardActions className="job_apply_title_section" sx={{ padding: "0" }}>
        <Box
          sx={{
            // marginLeft: { md: "2rem", xs: "0.2rem" },
            // width: "20%",
            // border:"1px solid green",
            paddingBottom:"10px"
          }}
        >
          <Button
            // variant="outlined"
            size="string"
            sx={{
              // marginLeft: "auto",
              // width: { xs: "8%", sm: "16%", md: "20%" },
              // border: "1px solid black",
              fontSize: { xs: "14px", sm: "18px" },
              // paddingLeft: "5px",
              zIndex: 200,
              // minWidth: "50px",
              fontWeight: "600",
              // fontStyle: "italic",
              color:"#f15b24",
              backgroundColor:"#ffffff",
              padding:"4px 10px",
              textTransform:"capitalize",
              fontFamily:"Roboto-Serif"
            }}
            onClick={() => openJobApplicationDialog()}
            // disabled={!user}
          >
            Apply
          </Button>
        </Box>
        {/* <Box
          className="job_apply_title_section--title"
          sx={{
            // backgroundColor: "#F15B24",
            width: { xs: "80%", md: "55%" },
            maxHeight: "2rem",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "10px", sm: "14px" },
              textAlign: "center",
              // padding: "2px 4px 2px 30px",
              fontStyle: "italic",
              height: "100%",
              marginTop: "3px",
            }}
          >
            {jobContent.title}
          </Typography>
        </Box> */}
      </CardActions>
    </Card>
  );
};
export default JobDetailCard;
